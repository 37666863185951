.icon {
    fill: var(--darkBlue);
}

.darkGrayIcon {
    fill: var(--darkGray);
}

.infoBlueIcon {
    fill: var(--infoBlue);
}
