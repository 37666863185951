.container {
  position: relative;
}

.snackbarContainer {
  position: fixed;
  z-index: 1500;
  display: flex;
  flex-direction: column-reverse; /* Stack snackbars vertically in reverse order */
}

.snackbar {
  margin-bottom: 0.5rem;
  width: 26.375rem;
  min-height: 4rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 2px 4px 13px 0px rgba(121, 121, 121, 0.18);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  transform: translateY(10px);
  animation: slideIn 0.5s ease-in-out forwards; /* Slide-in and fade animations */
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.type {
  color: var(--darkBlue);
}

.success > svg {
  fill: var(--successGreen);
}

.info > svg {
  fill: var(--infoBlue);
}

.warning > svg {
  fill: var(--warning);
}

.error > svg {
  fill: var(--errorRed);
}

.closeIconStyle > svg {
  fill: var(--midGray);
}

.message {
  color: var(--midGray);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
