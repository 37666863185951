#mt-router-outlet-main {
    width: 100%;
    height: 100%;
}

.home-section {
    position: relative;
    top: 0;
    left: 48px;
    width: calc(100% - 48px);
    height: 100%;
}