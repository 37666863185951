.responsiveCustomSelect {
  position: relative;
  border-bottom: 0.625rem;
}

.selectedOption {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.625rem 0 0.625rem;
}

.options {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  opacity: 0;
  max-height: 0;
  z-index: 999;
  box-shadow: 0.125rem 0.25rem 0.813rem 0 #7979792E;
  background-color: var(--white);
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.option {
  background-color: var(--white);
  color: var(--darkBlue);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0;
  border-bottom: 1px solid var(--textFieldOutlineGray);
  height: auto;
}

.option:hover {
  background-color: var(--blueGray);
  color: var(--darkBlue);
}

.selectedOption {
  background-color: var(--blueGrayLightDark);
  color: var(--darkBlue);
}

.splittedValue {
  display: inline-block;
  line-height: 1rem;
}

.optionValue {
  padding: 0 0.313rem;
  white-space: break-spaces;
  display: flex;
  align-items: baseline;
  word-break: break-word;
}

.fadeIn {
  opacity: 1;
}

.selectedOption.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.options.fadeIn {
  opacity: 1;
  max-height: 12.5rem;
}

/* Responsive Styles */
@media (max-width: 600px) {

  .selectedOption,
  .option {
    padding: 0.5rem;
  }
}

.displayBtn {
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.selectedValue {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}