.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 48px;
  z-index: 10;
  background-color: var(--navyBlue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebarMainNav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  align-items: center;
}

.sidebarActiveBtn {
  background-color: var(--blueHighlight);
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarSecondaryNav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
}

.navLink {
  width: 100%;
}

.sidebarIconStyle>svg {
  fill: var(--white);
}

.popupUserDetail {
  padding: 0.5rem !important;
}

.userDetailFont {
  display: block;
}

.logoutStyle {
  color: var(--darkBlue);
  cursor: pointer;
}

.userStyle {
  color: var(--darkGray);
  margin-bottom: 0.4rem;
}