:root {
  --dociphi-gray-color: #717171;
  --dociphi-green-color: #24B082;
  --dociphi-blue-color: #223E80;
  --dociphi-red-color: #dc3545;
  --dociphi-bg-color: #edecf1c2;
  --dociphi-blueCobaltDark-color: #2D4081;
  /* --dociphi-bg-color: #EEF7FA; */

  /* style for toaster progress line */
  --toastify-color-progress-light: #25a87c;

  /* height for workflow */
  --workflow-header-height: 3.75rem;
  --workflow-tabs-height: 2rem;
}

@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500&display=swap');

html,
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  overflow-x: hidden;
  background: var(--dociphi-bg-color);
  height: 100%;
}

p {
  margin: 0;
}

.App {
  height: 100%;
}

/* place here common css among components */
.mt-display-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mt-display-flex-row-normal {
  display: flex;
  flex-direction: row;
}

.mt-display-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mt-display-flex-column-space-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.mt-display-flex-column-normal {
  display: flex;
  flex-direction: column;
}

.mt-display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-classification-row {
  width: 100%;
  margin: 0.6rem 0rem 0.6rem 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.mt-horizontal-line {
  width: 100%;
  color: white;
}

.mt-column-color {
  color: rgb(3, 3, 109);
  font-weight: bold;
}

.mt-item-color {
  color: rgb(3, 3, 109);
}

.p-1 {
  padding: 1rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mt-key-column {
  width: 35%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-value-column {
  width: 40%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-confidence-column {
  width: 20%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-action-column {
  width: 13%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-key-modal-column {
  width: 80%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-value-modal-column {
  width: 10%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mt-confidence-modal-column {
  width: 10%;
  height: auto;
  justify-content: left;
  display: flex;
  align-items: center;
}

.mat-expansion-panel-body {
  padding: 0px;
}

.text-align-right {
  text-align: right;
}

.mt-spinner {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow-y: scroll;
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: #AEB3BC;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.blue-label {
  color: var(--dociphi-blue-color);
  font-size: 0.85rem;
}

.label-color {
  color: var(--dociphi-gray-color);
}

.value-color {
  color: var(--dociphi-gray-color);
}

.bg-primary {
  background-color: var(--dociphi-blue-color) !important;
}

.bg-success {
  background-color: var(--dociphi-green-color) !important;
}

.bg-danger {
  background-color: var(--dociphi-red-color) !important;
}

.bg-secondary {
  background-color: var(--dociphi-gray-color) !important;
}

.label-blue {
  color: var(--dociphi-blue-color);
}

.label-green {
  color: var(--dociphi-green-color);
}

.label-red {
  color: red;
}

.padding-right-1 {
  padding-right: 1rem;
}

.prev-btn {
  background-color: var(--dociphi-blue-color) !important;
}

/* hr:not([size]) {
  height: 0.2px !important;
} */

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: none !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

.btn-outline-primary {
  color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-primary {
  background-color: var(--dociphi-blue-color);
  color: #fff;
  border-color: var(--dociphi-blue-color);
}

.btn-primary:hover {
  background-color: #fff;
  color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  /* color: #fff;
  background-color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color); */
}

.btn-primary.disabled,
.btn-primary:disabled {
  /* color: #fff;
  border-color: #fff;
  background-color: var(--dociphi-blue-color); */
}

button>i {
  color: var(--dociphi-green-color) !important;
}

button:disabled>i,
#mt-zoom button:disabled>i {
  color: var(--dociphi-gray-color) !important;
}

.p-confirm-popup {
  border-radius: 3px;
  box-shadow: 0px 8px 12px rgb(9 30 66 / 15%), 0px 0px 1px rgb(9 30 66 / 31%);
}

.p-button.p-button-text {
  border: 1px solid rgb(174, 179, 188);
  color: #2D4081;
  border-radius: 0.25rem;
  text-transform: none;
  background-color: transparent;
  align-items: center;
  border-radius: 0.25rem;
  text-transform: none;
  font-weight: 500;
}

.p-button.p-button-text:enabled:active,
.p-button.p-button-text:enabled:hover {
  color: #2D4081;
  background-color: #E8F0FB;
  border: 1px solid rgb(174, 179, 188);
}

.p-button-label {
  font-weight: 500;
}

.p-button {
  background-color: #2D4081;
  ;
  color: #F5F8FA;
  border-radius: 0.25rem;
  text-transform: none;
  justify-content: center;
  border: 1.5px solid #2D4081;
}

.p-button:focus {
  box-shadow: none !important;
}

.p-button:enabled:active,
.p-button:enabled:hover {
  background-color: #395DAB;
}

.p-dropdown {
  width: 100%;
  border: none;
  background: none;
}

.p-dropdown .p-dropdown-trigger {
  background: #edecf1c2 !important;
  border-color: #edecf1c2 !important;
  width: 2rem !important;
  color: var(--dociphi-gray-color) !important;
}

.p-dropdown-label {
  background: #edecf1c2 !important;
  text-align: left !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  font-size: .91rem !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border: none;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: inherit !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #edecf1c2;
  border-left: 3px solid var(--dociphi-green-color);
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}

.mb-2,
.mx-2 {
  margin-bottom: .5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

/* table>thead>tr>th,
table>tbody>tr>td {
  color: var(--dociphi-gray-color);
} */

.form-control {
  width: 100%;
  height: 40px !important;
  padding: 0.5rem;
  font-weight: 400;
  display: block;
  font-size: .85rem !important;
  line-height: 1.428571429;
  color: var(--dociphi-gray-color)7;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border .2s linear, box-shadow .2s linear;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px #66afe999 !important;
}

.select-height-manage {
  height: 40px !important;
}

/* table thead {
  border-top-right-radius: 10px !important;
  padding: 10px 0px 10px 20px !important;
  background: #e0e0e054 !important;
  font-size: 0.9rem !important;
  color: black !important;
  font-weight: 400 !important;
  border-bottom-style: none !important;
  border-bottom: none !important;
} */

/* .table thead th {
  font-weight: 400 !important;
} */

/* table>thead>tr>th,
table>tbody>tr>td {
  height: 3.5rem !important;
  cursor: pointer !important;
  vertical-align: middle !important;
} */

/* table>tbody>tr>td {
  font-weight: 300 !important;
  background-color: #fff !important;
} */

/* table>tbody>tr.expanded,
table>tbody>tr.expansion,
table>tbody>tr:hover {
  transform: scale(1) !important;
  background-color: #fff !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
  transition: all 0.5s ease !important;
}

table>tbody>tr.expansion table>tbody>tr:hover {
  box-shadow: none !important;
  transform: none !important;
} */

.child-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.6rem !important;
  font-weight: 400 !important;
  transition: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  color: var(--dociphi-green-color) !important;
}

.p-accordion .p-accordion-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fff;
  border-color: #24B082 !important;
  color: var(--dociphi-green-color) !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--dociphi-green-color) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  height: 3rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link i {
  margin-right: 0.5rem;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: '' !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: '' !important;
}

.ngx-pagination .disabled {
  font-weight: 400 !important;
  color: var(--dociphi-gray-color) !important;
  cursor: not-allowed !important;
  letter-spacing: 0.2px !important;
  margin: 0 12px !important;
}

.ngx-pagination a,
.ngx-pagination button {
  padding: 5px 12px !important;
  line-height: 1.428571429 !important;
  text-decoration: none !important;
  color: var(--dociphi-gray-color) !important;
  background-color: transparent;
  font-weight: 400 !important;
  border: 1px solid transparent !important;
  margin: 0 2px !important;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  color: #fff !important;
  background: var(--dociphi-green-color) !important;
  border-color: white !important;
  cursor: pointer !important;
  border-radius: 3px !important;
}

.ngx-pagination .current {
  color: #fff !important;
  background: var(--dociphi-green-color) !important;
  cursor: pointer !important;
  border: 1px solid white !important;
  border-radius: 3px !important;
  padding: 5px 12px !important;
  line-height: 1.428571429 !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.disp-flex {
  display: flex;
}

.dataTables_info {
  padding-left: 3rem;
  font-size: 0.875rem;
}

.text-align-center {
  text-align: center;
}

.sortable-field>i {
  margin-left: 5px;
}

.sortable-field>i.pi-sort,
.sortable-field>i.pi-sort-alt {
  color: #5d5b5b;
  font-size: 0.8rem !important;
}

.btn-outline-success:hover,
.btn-outline-success:hover>i.pi,
.btn-outline-danger:hover,
.btn-outline-danger:hover>i.pi,
.btn-outline-secondary:hover,
.btn-outline-secondary:hover>i.pi,
.btn-outline-primary:hover,
.btn-outline-primary:hover>i.pi {
  color: #fff !important;
}

.btn-outline-success {
  background-color: #fff;
  color: var(--dociphi-green-color);
  border-color: var(--dociphi-green-color);
}

.btn-outline-success:hover,
.btn-outline-success:active {
  color: #fff;
  background-color: var(--dociphi-green-color);
  border-color: var(--dociphi-green-color);
}

.btn-outline-success>i.pi {
  color: inherit !important;
  margin-top: 0.2rem !important;
}

.btn-outline-danger {
  background-color: #fff;
  color: var(--dociphi-red-color);
  border-color: var(--dociphi-red-color);
}

.btn-outline-danger:hover,
.btn-outline-danger:active {
  color: #fff;
  background-color: var(--dociphi-red-color);
  border-color: var(--dociphi-red-color);
}

.btn-outline-danger>i.pi {
  color: inherit !important;
  margin-top: 0.2rem !important;
}

.btn-outline-secondary {
  background-color: #fff;
  color: var(--dociphi-gray-color);
  border-color: var(--dociphi-gray-color);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: #fff;
  background-color: var(--dociphi-gray-color);
  border-color: var(--dociphi-gray-color);
}

.btn-outline-secondary>i.pi {
  color: inherit !important;
  margin-top: 0.2rem !important;
}

.btn-outline-primary>i.pi {
  color: inherit !important;
  margin-top: 0.2rem !important;
}

.btn-outline-primary {
  background-color: #fff;
  color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: #fff;
  background-color: var(--dociphi-blue-color);
  border-color: var(--dociphi-blue-color);
}

.btn-success>i.pi,
.btn-danger>i.pi,
.btn-secondary>i.pi,
.btn-primary>i.pi {
  color: inherit !important;
  margin-top: 0.2rem !important;
}

.border-radius-35-p {
  border-radius: 35% !important;
}

.notification {
  position: relative;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -15px;
  padding: 2px 4px;
  border-radius: 27%;
  background: linear-gradient(180deg, #26a685, #233f80);
  color: #fff;
}

.pi {
  text-decoration: none !important;
  cursor: pointer !important;
}

.pi-external-link {
  font-size: 0.7rem;
}

.f-w-500 {
  font-weight: 500;
}

.float-right {
  float: right !important;
}

.truncate {
  display: inline-block;
  min-width: 60px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #15284B;
  padding: 0rem 0.5rem;
}

.truncate-disp-none {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mt-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.27rem 0 !important;
  width: 100%;
}

#mt-zoom {
  display: flex;
}

#mt-pagination button,
#mt-zoom button {
  border: none !important;
  background: none !important;
}

.status-img {
  width: 15px;
  height: 15px;
  vertical-align: initial;
}

.progress-loader {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.btn-sm-not {
  padding: 0.1rem 0.7rem;
}

.submission-table .pi-caret-up {
  color: var(--dociphi-green-color) !important;
}

.box {
  /* height: 100vh; */
  margin-left: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.extraction-display {
  width: 97%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  margin-left: 0.5rem;
  background: #fff;
}

.extraction-display-content {
  /* height: 72vh; */
  overflow-x: auto;
  overflow-y: auto;
}

canvas {
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  border: 1px solid #D4D5D8;
}

.percLabel {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 2px;
  color: var(--dociphi-gray-color);
  font-weight: 500;
}

.img-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  flex-direction: column;
  scroll-behavior: smooth;
  margin-left: 0.7rem;
}

/* hr {
  margin: 0.3rem 0 !important;
} */

.p-button.p-button-sm {
  padding: 0.25625rem 0.7375rem !important;
}



.search-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.rotate180 {
  transform: rotate(180deg);
}

#annot-btn.annotActive {
  /* background-color: lightgray !important; */
}

#annot-btn.annotInactive {
  /* color: var(--dociphi-gray-color) !important; */
  opacity: 0.6;
}

.progress-label {
  color: var(--dociphi-blue-color);
  font-weight: 500;
}

.deleteConfirmStyle {
  background-color: #fff;
  /* color: var(--dociphi-blue-color);
  padding: 8px 8px 0px 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px; */
  padding: 20px 16px 20px 16px;
  gap: 16px;
  isolation: isolate;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  position: relative;
}


.hitl-confirm-font-weight {
  font-weight: bold;
}

.disp-contents {
  display: contents;
}

.multiline-truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}