.responsiveCustomSelect {
  position: relative;
  border-bottom: 10px;
}

.selectedOption {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
}

.options {
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  max-height: 0;
  z-index: 999;
  box-shadow: 0.125rem 0.25rem 0.813rem 0 #7979792E;
  background-color: var(--white);
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;

}

.option {
  background-color: var(--white);
  color: var(--darkBlue);
  cursor: pointer;
  display: grid;
  justify-content: space-between;
  align-items: center;
  min-height: 1.5rem;
  padding: 0.125rem 0.24rem;
  border-bottom: 1px solid var(--textFieldOutlineGray);
}

.option:hover {
  background-color: var(--blueGray);
}

.splittedValue {
  display: inline-block;
  line-height: 1rem;
}

.selectText {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionValue {
  padding: 0px 5px 0px 5px;
  word-break: break-word;
}

.fadeIn {
  opacity: 1;
}

.selectedOption.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.options.fadeIn {
  opacity: 1;
  max-height: 200px;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .selectedOption,
  .option {
    padding: 8px;
  }
}

.grayColor {
  color: var(--eyeIconColor);
}

.startIcon {
  display: flex;
  align-items: center;
}