.popupStyle {
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  padding: 8px 16px;
  gap: 0.5rem;
  width: 18.563rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.popupTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #15284B;
  margin: 0;
}

.popupWarningText {
  margin: 0;
  color: #3C4C68;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  padding: 0.25rem 0 0.25rem 0.5rem;
  background-color: var(--white);
  border-radius: 0.25rem 0 0 0.25rem;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: var(--blueGrayLightDark);
}

.timerControls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBtnStyle {
  margin-bottom: 0.5rem;
}

.digits {
  color: var(--darkBlue);
}

.timerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmPopupHeader {
  font-weight: 500;
  font-size: 14px;
  color: #15284B;
  margin: 0;
}

.confirm-popup-content {
  color: #3C4C68;
  margin: 0;
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 0;
  padding-top: 8px;
  padding-right: 16px;
}

.p-confirm-popup .p-confirm-popup-footer {
  padding: 0;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-top: 16px;
}

.timerActions {
  cursor: pointer;
}

.timerActions:disabled {
  opacity: 0.4;
}

.reviewTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
  gap: 8px;
  background: #E4E6EF;
  border-radius: 1px;
  color: #3C4C68;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}

.stillHereStyle {
  margin-top: '1rem';
  display: 'flex';
  justify-content: 'flex-end';
}

.reviewCompleteContainer {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.reviewCompleteMsg {
  display: flex;
  align-items: center;
}

.reviewCompleteText {
  color: var(--navyBlue);
  padding-left: 0.5rem;
}

.loadingStyle {
  margin: 0;
  width: 4rem;
}

.timeStyle {
  margin-left: 0.25rem;
  width: 4rem;
}

.infoMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding-inline: 0.25rem;
  min-height: 2rem;
  background-color: var(--yellowExtremeLight);
  border-left: 4px solid var(--warning);
  border-radius: 4px;
}

.arrowRight {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid var(--warning);
}

.infoIconStyle {
  margin-bottom: 0.5rem;
}

.infoMsgText {
  color: var(--infoTextColor);
}

.buttonStyle:disabled {
  opacity: 0.3;
}

.confirmPopoverActionContainer {
  width: 13rem;
  padding: 1rem;
}

.confirmPopoverActionLabel p {
  text-align: center;
}

.confirmPopoverAction {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.timerBtns {
  margin-top: 0.17rem;
}