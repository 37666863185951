.tabsContainer {
  width: 100%;
  padding: 0rem 0.5rem 0rem 0rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.tabChip {
  width: 100%;
  text-align: left;
}

.tabChipText {
  background-color: var(--grayMid4);
  border-radius: 3px;
  padding: 0 0.5rem 0 0.5rem;
  color: var(--darkBlue);
}