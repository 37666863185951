.row-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: none;
}

.enable-add-row {
  cursor: pointer !important;
  opacity: 1;
}

.disable-add-row {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.add-row-text {
  display: flex;
  align-items: center;
  text-align: center;
  color: #3C4C68;
  margin-left: 4.5px;
  background: none;
}