@keyframes p-progress-spinner-color {
    0% {
        stroke: #38a68f;
    }
    100% {
        stroke: #38a68f;
    }
}

.p-blockui-document {
    background-color: transparent !important;
}