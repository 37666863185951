:root {
  --baseFontSize: 10px;
  --baseLineHeight: 10px;
  --bpsmall: 768px;
  --bpmedium: 1024px;
  --bplarge: 1366px;
  --bpxlarge: 1920px;
  --bpxxlarge: 2560px;
  background-color: white;

  /* define default colors */
  --darkBlue: #15284B;
  --navyBlue: #2D4081;
  --blueHighlight: #395DAB;
  --lightBlue: #E8F0FB;
  --greyBlue: #F5F8FA;
  --darkGray: #5A5A5A;
  --lightGray: #F6F6F6;
  --gray: #E4E6EF;
  --midGray: #969696;
  --successGreen: #23AF81;
  --infoBlue: #2271E7;
  --warning: #F39C19;
  --errorRed: #AA213F;
  --purple: #6554C0;
  --lightPurple: #EAE6FF;
  --yellow: #F39C19;
  --lightYellow: #FFDDAA;
  --lightYellow2: #FAF0DD;
  --green: #23AF81;
  --lightGreen: #D9FAEF;
  --blue: #2D4081;
  --chipsLightBlue: #C2DBFF;
  --blueDefault: #2271E7;
  --blueHover: #4891FF;
  --blueDisabled: #1E61C5;
  --grayDefault: #B2B2B2;
  --grayHover: #DBDBDB;
  --grayDisabled: #888888;
  --palewhite:#FFFFFF;
  --midBlue:#242E6C;
  --black: #1C1B1F;
  --lightergray:#E0E5E8;
  --failure:#DA271B;
  --textFieldOutlineGray: #D4D5D8;
  --white: #FFFFFF;
  --midleGrey:#D9D9D9;
  --blueishGrey:#E7EFF5;
  --solidGrey:#A3A3A3;
  --tableRowBorderGray: #E0E5E8;
  --lightCyanBlue: #E0E5E8;
  --eyeIconColor: #616E84;
  --afterWorkBlue:#242E6C;
  --loginLeftSection: #E8F2FE;
  --yellowExtremeLight: #FDF5E5;
  --infoTextColor: #3C4C68;
  --greenDefault: #24B082;
  --yellowDefault: #FFAB00;
  --defaultblue: #0065FF;
  --redDefault: #DE350B;
  --grayLight1: #F1F3F4;
  --grayMid1: #8992A1;
  --defaultBgColor: #F3F3F3;
  --grayMid2: #C5C5C5;
  --loginOuterBg: #DDECFE;
  --blueGrayLightDark: #DCE0F1;
  --grayLight1: #F1F3F4;
  --classified: #D6792D;
  --grayBorder: #d4d5d8;
  --grayMid4: #E1E2E2;
  --blueGray: #EAECF3;
  --lightRed: #F6E0DB;
  --grayMid2: #AEB3BC;
  --lightGreen: #D8FFF3;
  --lightPurple: #E5E2FC;
  --blueLight1: #DFECFF;
}

/* Define colors for dark theme */
[data-theme='dark'] {
  --darkBlue: #15284B;
  --navyBlue: #2D4081;
  --blueHighlight: #395DAB;
  --lightBlue: #E8F0FB;
  --greyBlue: #F5F8FA;
  --darkGray: #5A5A5A;
  --lightGray: #F6F6F6;
  --gray: #E4E6EF;
  --midGray: #969696;
  --successGreen: #23AF81;
  --infoBlue: #2271E7;
  --warning: #F39C19;
  --errorRed: #AA213F;
  --purple: #6554C0;
  --lightPurple: #EAE6FF;
  --yellow: #F39C19;
  --lightYellow: #FFDDAA;
  --lightYellow2: #FAF0DD;
  --green: #23AF81;
  --lightGreen: #D9FAEF;
  --blue: #2D4081;
  --chipsLightBlue: #C2DBFF;
  --blueDefault: #2271E7;
  --blueHover: #4891FF;
  --blueDisabled: #1E61C5;
  --grayDefault: #B2B2B2;
  --grayHover: #DBDBDB;
  --grayDisabled: #888888;
  --palewhite:#FFFFFF;
  --midBlue:#242E6C;
  --black: #1C1B1F;
  --lightergray:#E0E5E8;
  --failure:#DA271B;
  --textFieldOutlineGray: #D4D5D8;
  --white: #FFFFFF;
  --midleGrey:#D9D9D9;
  --blueishGrey:#E7EFF5;
  --solidGrey:#A3A3A3;
  --tableRowBorderGray: #E0E5E8;
  --eyeIconColor: #616E84;
  --afterWorkBlue:#242E6C;
  --loginLeftSection: #E8F2FE;
  --yellowExtremeLight: #FDF5E5;
  --infoTextColor: #3C4C68;
  --greenDefault: #24B082;
  --yellowDefault: #FFAB00;
  --defaultblue: #0065FF;
  --grayLight1: #F1F3F4;
  --grayMid1: #8992A1;
  --defaultBgColor: #F3F3F3;
  --grayMid2: #C5C5C5;
  --loginOuterBg: #DDECFE;
  --blueGrayLightDark: #DCE0F1;
  --grayLight1: #F1F3F4;
  --classified: #D6792D;
  --grayBorder: #d4d5d8;
  --grayMid4: #E1E2E2;
  --blueGray: #EAECF3;
  --lightRed: #F6E0DB;
  --grayMid2: #AEB3BC;
  --lightGreen: #D8FFF3;
  --lightPurple: #E5E2FC;
  --blueLight1: #DFECFF;
}

html {
  overflow-x: hidden;
  font-size: var(--baseFontSize);
  background-color: #eeedf2;
  background-color: #fff;
}

#root {
  height: 100%;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #c2c1c1 #dfdbdb;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.2);
    line-height: calc(var(--baseLineHeight) * 1.8);
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.4);
    line-height: calc(var(--baseLineHeight) * 2.1);
  }
}
@media screen and (min-width: 900px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.6);
    line-height: calc(var(--baseLineHeight) * 2.4);
  }
}
@media screen and (min-width: 1366px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.8);
    line-height: calc(var(--baseLineHeight) * 2.7);
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: calc(var(--baseFontSize) * 2);
    line-height: calc(var(--baseLineHeight) * 3);
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: calc(var(--baseFontSize) * 2.2);
    line-height: calc(var(--baseLineHeight) * 3.3);
  }
}

body {
  margin: 0 !important;
  background-color: white;
  height: 100%;
}

.titles {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.h2Bolder {
  /* 16px */
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.h5Bolder {
  /* 12px */
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.h6Bolder {
  /* 10px */
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.25rem;
}

/* Bold Fonts */

.headerText {
  /* 38px */
  font-size: 2.375rem;
  line-height: 3.125rem;
  font-weight: 500;
}

.h1Medium {
  /* 20px */
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.h2Medium {
  /* 16px */
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.h3Medium {
  /* 14.5px */
  font-size: 0.906rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.h4Medium {
  /* 14px */
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.h5Medium {
  /* 12px */
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.h6Medium {
  /* 10px */
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.25rem;
}

/* Regular Fonts */
.h1Regular {
  /* 20px */
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
}

.h2Regular {
  /* 16px */
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.h3Regular {
  /* 14.5px */
  font-size: 0.906rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.h4Regular {
  /* 14px */
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.h5Regular {
  /* 12px */
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.h6Regular {
  /* 10px */
  font-size: 0.625rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.h7Regular {
  /* 8px */
  font-size: 0.5rem;
  line-height: 1.25rem;
  font-weight: 400;
}