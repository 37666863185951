.iconButtonStyle {
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
}

/* .iconButtonStyle:hover {
  background-color: var(--lightGray);
} */

.iconButtonStyle:disabled:hover {
  cursor: not-allowed;
}