.switchContainer {
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: center;
  transition: background 0.3s ease;
}

.switchThumb {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateX(2px);
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disableSwitch {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}

.SwitchToggleCircle {
  border: 1.5px solid var(--black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}