.workflowHeaderContainer {
  height: var(--workflow-header-height);
  margin-inline: 0.25rem;
  padding-left: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.reviewingStyle {
  color: var(--darkBlue);
  white-space: pre;
}

.appDesc {
  color: var(--darkBlue);
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dociphiLogo {
  margin-right: 1rem;
}

.headerLeftPart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headerRightPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
}